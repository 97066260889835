@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&family=Roboto+Condensed:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

::-webkit-scrollbar {
  width: 7px;
  height: 0px;
  background: rgba(33, 28, 14, 0.09);
}
::-webkit-scrollbar:hover {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}
*{
  margin: 0;
  padding: 0;
}
.formError{
  color: #ca0707;
  font-family: "Outfit",sans-serif;
  margin-bottom: 15px;
}

.courseCardFont{
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  font-style: normal;
}
.courseCardPrice{
  font-weight: 600;
}
.landingText{
  color: #FFFFFF;
  font-size: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  font-family: 300;
  width: 60vw;
}
.landingAuthor{
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-family: 400;
}
.navText{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 17px;
  padding: 5px 10px;
  transition: transform 3ms;
}
.navText:hover{
  transform: scale(1.1);
}
.reviewModalHeading{
  color: #000000;
  font-family: "Outfit", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}

.courseDescHeading{
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px;
}
.courseDescText{
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.menuText{
  padding: 10px;
  cursor: pointer;
  transition: transform 5ms;
}
.menuText:hover{
  transform: scale(1.05);
}

.dropDownButton{
  font-size: 25px;
}